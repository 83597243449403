import React, { useEffect, useState } from 'react';
import { database } from '../firebase';
import { ref, increment, set, onValue } from 'firebase/database';

function VisitorCounter() {
    const [visitorCount, setVisitorCount] = useState(0);
    const [hasIncremented, setHasIncremented] = useState(false);

    useEffect(() => {
        // Reference to our visitors counter in the database
        const visitorCountRef = ref(database, 'visitors');

        // Increment count once per session
        if (!hasIncremented) {
            set(visitorCountRef, increment(1));
            setHasIncremented(true);
        }

        // Listen for changes to the visitor count
        const unsubscribe = onValue(visitorCountRef, (snapshot) => {
            const count = snapshot.val() || 0;
            setVisitorCount(count);
        });

        // Cleanup listener
        return () => unsubscribe();
    }, [hasIncremented]);

    return (
        <div className="visitor-counter">
            <span>👥 Visitors: {visitorCount}</span>
        </div>
    );
}

export default VisitorCounter; 