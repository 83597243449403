import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
    apiKey: "AIzaSyA8YIIFLbFqPT63gu6RL-GnqVTatPkVUBM",
    authDomain: "cursor-app-1-ed48a.firebaseapp.com",
    projectId: "cursor-app-1-ed48a",
    storageBucket: "cursor-app-1-ed48a.firebasestorage.app",
    messagingSenderId: "368301732937",
    appId: "1:368301732937:web:57311118143ffcc8113b98",
    measurementId: "G-XXXXXXXXXX",
    databaseURL: "https://cursor-app-1-ed48a-default-rtdb.firebaseio.com"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const analytics = getAnalytics(app);
const database = getDatabase(app);

export { db, auth, analytics, database };