import React, { useState, useEffect } from 'react';

function DetailedGitHubTracker() {
  const [lastUpdate, setLastUpdate] = useState(null);
  const [commitMessage, setCommitMessage] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = process.env.REACT_APP_GITHUB_TOKEN;
    
    fetch('https://api.github.com/repos/georgerakushkin/echoes-of-essence/branches', {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Accept': 'application/vnd.github.v3+json'
      }
    })
      .then(response => response.json())
      .then(branches => {
        const commitPromises = branches.map(branch => 
          fetch(`https://api.github.com/repos/georgerakushkin/echoes-of-essence/commits/${branch.name}`, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Accept': 'application/vnd.github.v3+json'
            }
          }).then(response => response.json())
        );

        Promise.all(commitPromises)
          .then(commits => {
            const mostRecent = commits.reduce((latest, current) => {
              const currentDate = new Date(current.commit.author.date);
              return !latest || currentDate > new Date(latest.commit.author.date) ? current : latest;
            }, null);

            if (mostRecent) {
              setLastUpdate(new Date(mostRecent.commit.author.date));
              setCommitMessage(mostRecent.commit.message);
            }
            setLoading(false);
          });
      })
      .catch(error => {
        console.error('Error fetching GitHub data:', error);
        setLoading(false);
      });
  }, []);

  if (loading) return <div className="github-tracker">Loading last update...</div>;
  if (!lastUpdate) return null;

  const formatTime = (date) => {
    return date.toLocaleTimeString('en-US', { 
      hour: 'numeric', 
      minute: '2-digit',
      hour12: true 
    });
  };

  return (
    <div className="tracker-container">
      <div className="github-tracker">
        <span className="github-icon">🎮</span>
        <div className="tracker-content">
          <div>Game last updated on {lastUpdate.toLocaleDateString()} at {formatTime(lastUpdate)}</div>
          {commitMessage && (
            <div className="commit-message-inline">Latest commit: "{commitMessage}"</div>
          )}
        </div>
      </div>
      <div className="tracker-subtitle">info pulled directly from github</div>
    </div>
  );
}

export default DetailedGitHubTracker; 