import React, { useState } from 'react';
import { db } from '../firebase';
import { collection, addDoc } from 'firebase/firestore';

function SteamNotification({ onClose }) {
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      await addDoc(collection(db, 'steamNotifications'), {
        email,
        timestamp: new Date(),
      });
      setStatus('success');
      setEmail('');
      setTimeout(() => {
        onClose();
      }, 2000);
    } catch (error) {
      setStatus('error');
    }
    setIsSubmitting(false);
  };

  const handleClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div className="steam-notification" onClick={handleClick}>
      <h3>Get notified when the Steam page is live!</h3>
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email"
          required
        />
        <button type="submit" disabled={isSubmitting}>
          {isSubmitting ? 'Submitting...' : 'Notify Me'}
        </button>
      </form>
      {status === 'success' && (
        <p className="success-message">Thanks! I'll send you an email when the page is up 😊</p>
      )}
      {status === 'error' && (
        <p className="error-message">Oops! Something went wrong. Please try again.</p>
      )}
    </div>
  );
}

export default SteamNotification; 