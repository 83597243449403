import React, { useState, useEffect } from 'react';

function GitHubTracker() {
  const [lastUpdate, setLastUpdate] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = process.env.REACT_APP_GITHUB_TOKEN;
    
    // Get all branches first
    fetch('https://api.github.com/repos/georgerakushkin/echoes-of-essence/branches', {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Accept': 'application/vnd.github.v3+json'
      }
    })
      .then(response => response.json())
      .then(branches => {
        // Get latest commit from each branch
        const commitPromises = branches.map(branch => 
          fetch(`https://api.github.com/repos/georgerakushkin/echoes-of-essence/commits/${branch.name}`, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Accept': 'application/vnd.github.v3+json'
            }
          }).then(response => response.json())
        );

        // Find the most recent commit
        Promise.all(commitPromises)
          .then(commits => {
            const mostRecent = commits.reduce((latest, current) => {
              const currentDate = new Date(current.commit.author.date);
              return !latest || currentDate > new Date(latest.commit.author.date) ? current : latest;
            }, null);

            if (mostRecent) {
              setLastUpdate(new Date(mostRecent.commit.author.date));
            }
            setLoading(false);
          });
      })
      .catch(error => {
        console.error('Error fetching GitHub data:', error);
        setLoading(false);
      });
  }, []);

  if (loading) return <div className="github-tracker">Loading last update...</div>;
  if (!lastUpdate) return null;

  const formatTime = (date) => {
    return date.toLocaleTimeString('en-US', { 
      hour: 'numeric', 
      minute: '2-digit',
      hour12: true 
    });
  };

  return (
    <div className="github-tracker">
      <span className="github-icon">🎮</span>
      Game last updated on {lastUpdate.toLocaleDateString()} at {formatTime(lastUpdate)}
    </div>
  );
}

export default GitHubTracker; 