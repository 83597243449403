import React, { useState, useEffect } from 'react';

function DevlogFeed() {
  const [devlogs, setDevlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDevlogs = async () => {
      try {
        const response = await fetch(
          `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=PLTkBTQbztqSWGdUsbqALB4xTLuIsvb3PW&maxResults=3&key=${process.env.REACT_APP_YOUTUBE_API_KEY}`
        );
        const data = await response.json();
        
        console.log('YouTube API Response:', data);
        
        if (data.error) {
          setError(data.error.message);
          setLoading(false);
          return;
        }
        
        setDevlogs(data.items || []);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching devlogs:', error);
        setError('Failed to fetch devlogs');
        setLoading(false);
      }
    };

    fetchDevlogs();
  }, []);

  if (loading) return <div>Loading devlogs...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!devlogs.length) return <div>No devlogs available</div>;

  return (
    <div className="devlog-feed">
      <h3>Recent Devlogs</h3>
      <div className="devlog-grid">
        {devlogs.map((devlog) => (
          <div key={devlog.id} className="devlog-card">
            <a 
              href={`https://youtube.com/watch?v=${devlog.snippet.resourceId.videoId}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img 
                src={devlog.snippet.thumbnails.medium.url} 
                alt={devlog.snippet.title}
                className="devlog-thumbnail"
              />
              <h4>{devlog.snippet.title}</h4>
              <p>{new Date(devlog.snippet.publishedAt).toLocaleDateString()}</p>
            </a>
          </div>
        ))}
      </div>
    </div>
  );
}

export default DevlogFeed; 