import React, { useState, useEffect } from 'react';
import GitHubTracker from './components/GitHubTracker';
import VisitorCounter from './components/VisitorCounter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSteam, faYoutube, faTwitch } from '@fortawesome/free-brands-svg-icons';
import { analytics } from './firebase';
import { logEvent } from 'firebase/analytics';
import './App.css';
import DetailedGitHubTracker from './components/DetailedGitHubTracker';
import DevlogFeed from './components/DevlogFeed';
import SteamNotification from './components/SteamNotification';

const scrollToSection = (sectionId, e) => {
  e.preventDefault();
  const element = document.getElementById(sectionId);
  if (element) {
    const targetPosition = element.offsetTop;
    const startPosition = window.pageYOffset;
    const distance = targetPosition - startPosition;
    const duration = 1000; // Duration in milliseconds
    let start = null;

    const animation = (currentTime) => {
      if (!start) start = currentTime;
      const timeElapsed = currentTime - start;
      const progress = Math.min(timeElapsed / duration, 1);
      
      // Easing function for smoother animation
      const ease = t => t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
      
      window.scrollTo(0, startPosition + distance * ease(progress));

      if (timeElapsed < duration) {
        requestAnimationFrame(animation);
      }
    };

    requestAnimationFrame(animation);
  }
};

function App() {
  const [showResponse, setShowResponse] = useState(false);
  const [showSteamMessage, setShowSteamMessage] = useState(false);
  const [isShaking, setIsShaking] = useState(false);
  const [enterSite, setEnterSite] = useState(false);
  const [particleInterval, setParticleInterval] = useState(null);
  const [activeButton, setActiveButton] = useState(null); 

  const handleWishlist = (buttonId) => {
    logEvent(analytics, 'steam_wishlist_click');
    setShowSteamMessage(true);
    setIsShaking(true);
    setActiveButton(buttonId);
  };

  const handleGameStatusClick = () => {
    logEvent(analytics, 'game_status_check');
    setShowResponse(true);
  };

  const startParticles = (e) => {
    if (!particleInterval) {
      const interval = setInterval(() => {
        const button = e.target;
        const rect = button.getBoundingClientRect();
        const particles = 5;

        for (let i = 0; i < particles; i++) {
          const particle = document.createElement('div');
          particle.className = 'particle';
          
          const startX = Math.random() * (rect.width - 4);
          particle.style.left = startX + 'px';
          particle.style.top = '0';
          
          const x = (Math.random() - 0.5) * (rect.width * 0.5);
          const y = -(100 + Math.random() * 150);
          
          particle.style.setProperty('--x', `${x}px`);
          particle.style.setProperty('--y', `${y}px`);
          
          button.appendChild(particle);
          
          setTimeout(() => {
            if (particle && particle.parentNode) {
              particle.parentNode.removeChild(particle);
            }
          }, 1000);
        }
      }, 80);
      
      setParticleInterval(interval);
    }
  };

  const stopParticles = () => {
    if (particleInterval) {
      clearInterval(particleInterval);
      setParticleInterval(null);
    }
  };

  const handleClickOutside = (e) => {
    if (showSteamMessage && 
        !e.target.closest('.steam-notification') && 
        !e.target.closest('.steam-wishlist-button')) {
      setShowSteamMessage(false);
      setIsShaking(false);
      setActiveButton(null);
    }
    
    if (showResponse &&
        !e.target.closest('.status-response') && 
        !e.target.closest('.status-button')) {
      setShowResponse(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showSteamMessage, showResponse]);

  return (
    <>
      <div className={`landing-page ${enterSite ? 'fade-out' : ''}`}>
        <button 
          className="enter-site-button"
          onClick={() => setEnterSite(true)}
          onMouseEnter={startParticles}
          onMouseLeave={stopParticles}
        >
          Enter
        </button>
      </div>

      <div className={`main-content ${enterSite ? 'fade-in' : ''}`}>
        <div className="App dark-mode">
          <nav className="navbar">
            <div className="logo-container">
              <h1 className="site-title">Echoes of Essence</h1>
            </div>
            <div className="tracker-nav">
              <React.Suspense fallback={<div>Loading...</div>}>
                <GitHubTracker />
              </React.Suspense>
            </div>
            <div className="nav-links">
              <a href="#home" onClick={(e) => scrollToSection('home', e)}>Home</a>
              <a href="#about" onClick={(e) => scrollToSection('about', e)}>About</a>
              <a href="#progress" onClick={(e) => scrollToSection('progress', e)}>Track Progress</a>
              <a href="https://youtube.com/brakenmusic" target="_blank" rel="noopener noreferrer">YouTube</a>
              <a href="https://twitch.com/brakenmusic" target="_blank" rel="noopener noreferrer">Twitch</a>
            </div>
          </nav>

          <div className="game-status-section">
            <div className="status-container">
              <div className="upgrade-columns">
                <img 
                  src={require('./assets/upgrade_column1.png')} 
                  alt="Upgrade Column 1" 
                  className="upgrade-column"
                />
                <div className="status-content">
                  <button 
                    className="status-button pulse" 
                    onClick={handleGameStatusClick}
                  >
                    Is the game done?
                  </button>
                  <div className={`status-response ${showResponse ? 'show' : ''}`}>
                    <h2>No 🙃</h2>
                    <p>But you can track updates below</p>
                    <button 
                      className="okay-button" 
                      onClick={() => setShowResponse(false)}
                    >
                      Okay, I'll wait!
                    </button>
                  </div>
                </div>
                <img 
                  src={require('./assets/upgrade_column2.png')} 
                  alt="Upgrade Column 2" 
                  className="upgrade-column"
                />
              </div>
              <div className="steam-button-container">
                <button 
                  className={`steam-wishlist-button ${isShaking ? 'shaking' : ''}`}
                  onClick={() => handleWishlist('top')}
                  style={{ marginTop: '1rem' }}
                >
                  <FontAwesomeIcon icon={faSteam} className="steam-icon" />
                  Wishlist on Steam
                </button>
                <div className={`steam-message ${showSteamMessage && activeButton === 'top' ? 'show' : ''}`}>
                  <SteamNotification onClose={() => {
                    setShowSteamMessage(false);
                    setIsShaking(false);
                    setActiveButton(null);
                  }} />
                </div>
              </div>
            </div>
          </div>

          <div className="testzone-section">
            <div className="video-container">
              <iframe
                className="testzone-video-bg"
                title="Game Preview Video"
                src="https://www.youtube.com/embed/8eGgWBTZnv0?autoplay=1&mute=1&loop=1&playlist=8eGgWBTZnv0&controls=0&showinfo=0&rel=0&version=3&modestbranding=1&playsinline=1&enablejsapi=1&origin=https://cursor-app-1-ed48a.web.app&widget_referrer=https://cursor-app-1-ed48a.web.app&html5=1&iv_load_policy=3&fs=0&disablekb=1&vq=hd1080"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            <div className="steam-button-container">
              <button 
                className={`steam-wishlist-button ${isShaking ? 'shaking' : ''}`}
                onClick={() => handleWishlist('bottom')}
              >
                <FontAwesomeIcon icon={faSteam} className="steam-icon" />
                Wishlist on Steam
              </button>
              <div className={`steam-message ${showSteamMessage && activeButton === 'bottom' ? 'show' : ''}`}>
                <SteamNotification onClose={() => {
                  setShowSteamMessage(false);
                  setIsShaking(false);
                  setActiveButton(null);
                }} />
              </div>
            </div>
          </div>

          <section id="progress" className="progress-section">
            <div className="progress-container">
              <div className="ore-purchaser-container">
                <img 
                  src={require('./assets/ore_purchaser.png')} 
                  alt="Ore Purchaser" 
                  className="ore-purchaser-image"
                />
              </div>
              <div className="progress-content">
                <div className="progress-text">
                  <h2>Track Development Progress</h2>
                  <p className="progress-description">
                    This tracker updates automatically whenever I push new code.
                    Follow along with the development journey!
                  </p>
                  <div className="progress-tracker">
                    <React.Suspense fallback={<div>Loading...</div>}>
                      <DetailedGitHubTracker />
                    </React.Suspense>
                  </div>
                  <DevlogFeed />
                </div>
                <div className="progress-images">
                  <img 
                    src={require('./assets/computer.png')} 
                    alt="Computer" 
                    className="computer-image"
                  />
                </div>
              </div>
            </div>
          </section>

          <section id="about" className="about-section">
            <div className="about-container">
              <div className="about-text">
                <h2>About The Game</h2>
                <p>
                  Echoes of Essence is a survivor-like RPG that draws inspiration from 
                  Vampire Survivors, Archvale, Forager, and Risk of Rain.
                </p>
                <p>
                  Combine powerful abilities, collect resources, and survive against 
                  increasingly challenging waves of enemies in this unique blend of 
                  survival and RPG mechanics.
                </p>
              </div>
              <div className="about-image">
                <img src={require('./assets/base.png')} alt="Game Screenshot" />
              </div>
            </div>
          </section>
        </div>
      </div>

      <footer className="site-footer">
        <div className="footer-content">
          <div className="social-links">
            <a href="https://youtube.com/brakenmusic" target="_blank" rel="noopener noreferrer" className="social-button youtube">
              <FontAwesomeIcon icon={faYoutube} /> YouTube
            </a>
            <a href="https://twitch.com/brakenmusic" target="_blank" rel="noopener noreferrer" className="social-button twitch">
              <FontAwesomeIcon icon={faTwitch} /> Twitch
            </a>
          </div>
          <VisitorCounter />
        </div>
      </footer>
    </>
  );
}

export default App;
